<template>
  <v-dialog
    :value="true"
    transition="fade-transition"
    fullscreen
  >
    <div>
      <v-card
        :key="clickedDayData.date"
        v-on-clickaway="closeDeleteDialog"
        :style="positionDialog"
      >
        <v-card-title>
          <v-row>
            <v-col>
              <v-row class="d-flex align-center justify-space-between pl-2 pb-2">
                <div class="h6" v-text="$t('common.delete')" />
                <v-btn icon color="black" @click="$emit('closeDeleteDialog')">
                  <v-icon small color="primary">
                    close
                  </v-icon>
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <v-row>
            <v-col class="d-flex flex-column justify-center align-start">
              <span
                class="caption font-weight-medium mt-2"
                style="cursor: pointer;"
                @click="$emit('deleteThisActivity', { targetPeriodicGroup: false })"
                v-text="$t('project.academy.calendar.deleteOne')"
              />
              <span
                v-if="selectedEvent && selectedEvent.periodicity && selectedEvent.periodicity.isPeriodic"
                class="caption font-weight-medium"
                style="cursor: pointer;"
                @click="$emit('deleteThisActivity', { targetPeriodicGroup: true })"
                v-text="$t('project.academy.calendar.deleteGroup')"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
import { mixin as clickaway } from 'vue-clickaway'

export default {
  name: 'CalendarDeleteDialog',
  mixins: [clickaway],
  props: {
    clickedDayData: {
      type: Object,
      default: () => {},
    },
    positionDialog: {
      type: Object,
      default: () => {},
    },
    selectedEvent: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    closeDeleteDialog() {
      this.$emit('closeDeleteDialog')
    },
  },
}
</script>
